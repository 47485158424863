import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import MenuIcon from '@assets/icons/MenuIcon';
import CloseIcon from '@assets/icons/CloseIcon';
import { theme } from '@styles';
import { MenuData, PrismicFooter } from '@appTypes';
import { BLACK_HAZE, LIMED_SPRUCE, MYSTIC, OUTER_SPACE, WHITE } from '@constants';
import ChevronBottomIcon from '@assets/icons/ChevronBottomIcon';
import ChevronTopIcon from '@assets/icons/ChevronTopIcon';
import OutsideClickHandler from 'react-outside-click-handler';
import { useWindowScroll, useWindowSize } from 'react-use';
import CustomText from './CustomText';
import { NavigationThemeContext } from '../context/NavigationThemeContext';
import { isBrowser } from '@utils';

const { colors, breakpoints } = theme;

type Props = {
  menuData: MenuData;
  footerData: PrismicFooter;
};

const Menu: React.FC<Props> = ({ menuData, footerData }) => {
  const { copyright_text, footer_logo } = footerData;
  const [menuOpen, setMenuOpen] = useState(false);
  const { body } = menuData;
  const menuLinks = body;
  const [openedSubmenu, setOpenedSubmenu] = useState<string | undefined>('string');
  const { width } = useWindowSize();
  const { y } = useWindowScroll();
  const { navigationTheme } = useContext(NavigationThemeContext);

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const handleAnchorClick = () => {
    setMenuOpen(false);
  };

  const renderLinks = (payload: { isMobile: boolean }) => {
    const { isMobile } = payload;
    return menuLinks.map((linkItem) => {
      if (linkItem.primary.link && linkItem.primary.link.includes('https://team') && !linkItem.primary.link_is_button) {
        return (
          <ExternalLink
            fontSize={16}
            lineHeight={'27px'}
            onClick={() => {
              window.open(`${linkItem.primary.link}${window.location.search}`, '_blank');
            }}
          >
            {linkItem.primary.link_label}
          </ExternalLink>
        );
      } else if (isBrowser() && linkItem.primary.link === '/professionals' && window.location.pathname === '/physio') {
        return (
          <MainLink
            activeStyle={{ fontWeight: '700' }}
            rel='noreferrer'
            to={`/physio${isBrowser() ? window.location.search : ''}`}
            key={'For Physio'}
            target={linkItem.primary.link_is_external ? '_blank' : 'initial'}
            onClick={() => {
              handleAnchorClick();
            }}
          >
            <LinkLabel
              id={linkItem.primary.link_id}
              onClick={() => {
                setOpenedSubmenu(linkItem.primary.link_label);
              }}
            >
              <div id={linkItem.primary.link_id}>For Physio</div>
            </LinkLabel>
          </MainLink>
        );
      } else
        return (
          <MainLink
            activeStyle={{ fontWeight: '700' }}
            rel='noreferrer'
            to={
              linkItem.primary.link
                ? linkItem.primary.link.includes('#')
                  ? `/${linkItem.primary.link}`
                  : `${linkItem.primary.link}${isBrowser() ? window.location.search : ''}`
                : null
            }
            key={linkItem.primary.link_label}
            target={linkItem.primary.link_is_external ? '_blank' : 'initial'}
            onClick={() => {
              handleAnchorClick();
            }}
          >
            <LinkLabel
              theme={navigationTheme}
              isButton={linkItem.primary.link_is_button}
              id={linkItem.primary.link_id}
              onClick={() => {
                setOpenedSubmenu(linkItem.primary.link_label);
              }}
            >
              <div id={linkItem.primary.link_id}>{linkItem.primary.link_label}</div>
              {linkItem.items.length > 0 && (
                <ChevronIconWrapper>
                  {openedSubmenu === linkItem.primary.link_label ? (
                    <ChevronTopIcon stroke={isMobile ? WHITE : OUTER_SPACE} />
                  ) : (
                    <ChevronBottomIcon stroke={isMobile ? WHITE : OUTER_SPACE} />
                  )}
                </ChevronIconWrapper>
              )}
            </LinkLabel>
            {linkItem.items.length > 0 && openedSubmenu === linkItem.primary.link_label && (
              <OutsideClickHandler
                disabled={width < 769}
                onOutsideClick={() => {
                  setOpenedSubmenu(undefined);
                }}
              >
                <InnerLinksWrapper>
                  {linkItem.items.map((linkInnerItem, index) => {
                    return (
                      !linkInnerItem.hidden && (
                        <InnerLink
                          isFirst={index === 0}
                          isLast={index === linkItem.items.length - 1}
                          activeStyle={{ fontWeight: '700' }}
                          to={
                            linkInnerItem.inner_link
                              ? linkInnerItem.inner_link.includes('#')
                                ? `${linkInnerItem.inner_link}`
                                : `${linkInnerItem.inner_link}${location.search}`
                              : null
                          }
                          onClick={() => {
                            handleAnchorClick();
                            setOpenedSubmenu(undefined);
                          }}
                          id={linkInnerItem.inner_link_id}
                          key={linkInnerItem.inner_link_label}
                        >
                          {linkInnerItem.inner_link_label}
                        </InnerLink>
                      )
                    );
                  })}
                </InnerLinksWrapper>
              </OutsideClickHandler>
            )}
          </MainLink>
        );
    });
  };

  return (
    <Container>
      <MenuIcons opened={menuOpen} scrollY={y}>
        {!menuOpen ? (
          <MenuIcon onClick={handleMenuClick} width={24} color='#000' />
        ) : (
          <CloseIcon onClick={handleMenuClick} width={24} color='#fff' />
        )}
      </MenuIcons>

      <DesktopNavigation>
        <MainLinksWrapper>{renderLinks({ isMobile: false })}</MainLinksWrapper>
      </DesktopNavigation>

      {menuOpen && (
        <MobileNavigation>
          <MainLinksWrapper>{renderLinks({ isMobile: true })}</MainLinksWrapper>
          <FooterContainer>
            <img src={footer_logo.url} />
            <FooterHeader>
              <div>{copyright_text}</div>
            </FooterHeader>
          </FooterContainer>
        </MobileNavigation>
      )}
    </Container>
  );
};

export default Menu;

const ChevronIconWrapper = styled.div``;

const ExternalLink = styled(CustomText)`
  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
`;

const LinkLabel = styled.div<{ isButton?: boolean }>`
  display: flex;
  align-items: flex-end;
  svg {
    margin-left: 2px;
  }
  &:hover {
    opacity: 0.75;
  }
  color: #fff;
  font-size: ${({ isButton }) => (isButton ? '16px' : '24px')};
  line-height: 36px;
  letter-spacing: -0.2px;
  margin-bottom: 24px;
  justify-content: space-between;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    color: ${({ isButton, theme }) =>
      (theme === 'dark' && !isButton) || (theme === 'light' && isButton) ? '#fff' : OUTER_SPACE};
    font-size: ${({ isButton }) => (isButton ? '14px' : '16px')};
    font-weight: ${({ isButton }) => (isButton ? '700' : 'auto')};
    border-radius: 12px;
    line-height: 18.2px;
    margin-bottom: 0px;
  }
  ${({ isButton, theme }) =>
    isButton &&
    `
    padding: 16px 24px;
    background-color: ${LIMED_SPRUCE};
    transform: translateY(0px);
    &:hover {
      cursor: pointer;
      transform: translateY(-3px);
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
      opacity: 1;
    }
    transition: all 250ms ease-in-out;
    border-radius: 12px;
    display: inline-flex;
    line-height: 22.2px;
    font-weight: 700;
    @media screen and (min-width: ${breakpoints.smallLaptop}) {
      background-color: ${theme === 'dark' ? WHITE : OUTER_SPACE};
    }
  `}
`;

const MainLinksWrapper = styled.div`
  margin-top: 130px;
  padding: 0 32px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: initial;
    padding: initial;
    gap: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InnerLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 16px;
    background: #fff;
    border-radius: 12px;
    width: 230px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid ${MYSTIC};
  }
`;

const MainLink = styled(Link)`
  position: relative;
  text-decoration: none;
  cursor: pointer;
`;

const InnerLink = styled(Link)<{ isFirst: boolean; isLast: boolean }>`
  text-decoration: none;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.2px;
  color: #fff;
  margin-bottom: 24px;
  padding: 0 16px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    color: ${OUTER_SPACE};
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 27px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top-left-radius: ${({ isFirst }) => (isFirst ? '12px' : '0px')};
    border-top-right-radius: ${({ isFirst }) => (isFirst ? '12px' : '0px')};
    border-bottom-left-radius: ${({ isLast }) => (isLast ? '12px' : '0px')};
    border-bottom-right-radius: ${({ isLast }) => (isLast ? '12px' : '0px')};
    &:hover {
      background: ${BLACK_HAZE};
    }
  }
`;

const FooterContainer = styled.div`
  padding: 50px 24px 0px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 118px;
  }
  @media screen and (min-width: ${breakpoints.phone}) {
    padding: 80px 32px 0px 32px;
  }
`;

const FooterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${colors.nevada};
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 400;
`;

const Container = styled.div`
  height: 27px;
  display: flex;
  align-items: center;
  user-select: none;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    height: initial;
    width: 100%;
    justify-content: flex-end;
    z-index: 1;
  }
`;

const MobileNavigation = styled.div`
  background: ${colors.outerSpace};
  position: fixed;
  z-index: 998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  justify-content: space-between;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 110px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: none;
  }
`;

const DesktopNavigation = styled.div`
  display: none;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: flex;
  }
`;

const MenuIcons = styled.div<{ opened: boolean; scrollY: number }>`
  position: ${({ scrollY }) => (scrollY > 177 ? 'fixed' : 'initial')};
  background: ${({ opened }) => (opened ? 'initial' : '#fff')};
  border-radius: 8px;
  padding: 6px 8px;
  right: 24px;
  z-index: 999;
  top: 28px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: none;
    top: initial;
  }
`;
